import React, {useState} from 'react';
import {CircularProgress, IconButton, Modal} from '@mui/material';
import Shopify from "../../assets/images/shopify.png"
import WooCommerce from "../../assets/images/woocommerce.png"
import Etsy from "../../assets/images/etsy.png"
import EBay from "../../assets/images/ebay.png"
import AddStore from "../../assets/images/add_store.png"
import Disconnect from "../../assets/images/disconnect.png"

import './style.scss';
import {Close} from "@mui/icons-material";
import PLatformClient from "../client/Client";
import {Link} from "react-router-dom";
import {FaInfo} from "react-icons/fa";
import {CiCircleInfo} from "react-icons/ci";

const StoresTableComponent = ({ stores, setStores }) => {
    const [expanded, setExpanded] = useState(stores?.length === 0);
    const [disconnect, setDisconnect] = useState(null);
    const [showCredentialsForm, setShowCredentialsForm] = useState(null);
    const [shopifyAdminToken, setShopifyAdminToken] = useState(null);
    const [storeName, setStoreName] = useState(null);
    const [storeUrl, setStoreUrl] = useState(null);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);

    const isURL = (str) => {
        // Regular expression to match a URL pattern
        const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;

        // Test the input string against the regular expression
        return urlPattern.test(str);
    }

    const handleStoreRegistration = async () => {
        if (!storeName  || storeName === "") {
            setError("Store name cannot be empty.")
            return;
        }

        if (!isURL(storeUrl)) {
            setError("Store URL must be valid.")
            return;
        }

        setLoading(true);
        if (showCredentialsForm === "WooCommerce") {
            let store = { "name": storeName, "url": storeUrl, "platform": showCredentialsForm.toUpperCase(),"platformAuthData": {} }
            const response = await PLatformClient.post('/stores', store);
            window.location.href = storeUrl + "/wc-auth/v1/authorize?app_name=Drop-Shipping.AI&scope=read_write&user_id=" + response?.id + "&return_url=https%3A%2F%2Fplatform.drop-shipping.ai%2Fstores&callback_url=https%3A%2F%2Fapi.drop-shipping.ai%2Fintegrations%2Fwoocommerce"
            handleCloseExpand();
            setLoading(false);
            return;
        }
        let authData = { "access_token": shopifyAdminToken }
        let store = { "name": storeName, "url": storeUrl, "platform": showCredentialsForm.toUpperCase(),"platformAuthData": authData }
        const response = await PLatformClient.post('/stores', store);
        setStores((prev) => [...prev, response])
        handleCloseExpand();
        setLoading(false);
    }

    const removeStore = async () => {
        setLoading(true);
        const response = await PLatformClient.delete('/stores/' + disconnect?.id);
        setStores((prev) =>
            prev.filter((store) => store?.id !== disconnect?.id)
        );
        setDisconnect(null)
        setLoading(false);

    }

    const handleExpand = (item) => {
        setExpanded(item);
    };

    const handleCloseExpand = () => {
        setExpanded(null);
        setShowCredentialsForm(null);
        setError(null)
    };
    const platformIcon = (platform) => {
        switch (platform) {
            case "SHOPIFY":
                return <img className="platform-image" src={Shopify}/>;
            case "EBAY":
                return <img className="platform-image" src={EBay}/>
            case "WOOCOMMERCE":
                return <img className="platform-image" src={WooCommerce}/>
        }
        return <img className="platform-image" src={Shopify}/>;
    }

    return (
        <div className="container">

            <div className="head-container">
                <span style={{ fontSize: 18 }}>Store Name</span>
                <span style={{ fontSize: 18 }}>Website</span>
               <img style={{ cursor: 'pointer' }} onClick={() => handleExpand(true)} src={AddStore} width={"140px"}/>
            </div>


            <div className="stores-container">
                {stores?.map((item) => (
                    <div className="store-container">
                        <span style={{ fontSize: 18, display: 'inline-block', textAlign: 'center' }}>{platformIcon(item.platform)}{item.name}</span>
                        <span style={{ fontSize: 18 }}>{item.url}</span>
                        <img onClick={() => setDisconnect(item)} style={{ cursor: 'pointer' }} src={Disconnect} width={"120px"}/>
                    </div>
                ))}
            </div>
            <Modal open={disconnect !== null} onClose={() => setDisconnect(null)}>
                <div style={{ overflow: 'hidden' }} className="modal-container">
                    <IconButton
                        className="close-icon"
                        onClick={() => setDisconnect(null)}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                    <h1>Disconnect Store - {disconnect?.name}?</h1>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "end", gap: 10 }}>
                        { loading && <CircularProgress style={{ width: 45, height: 45 }} />}
                        <button style={{ cursor: 'pointer' }} onClick={removeStore} className="delete-button">Disconnect</button>
                    </div>
                </div>
            </Modal>
            <Modal style={{ overflow: 'hidden' }} open={expanded} onClose={() => handleCloseExpand()}>
                <div className="modal-container">
                    { showCredentialsForm !== null && (<p onClick={() => setShowCredentialsForm(null)} style={{ position: 'absolute', top: 20, left: 40, cursor: 'pointer' }}>← back</p>)}
                    <IconButton
                        className="close-icon"
                        onClick={() => handleCloseExpand()}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                    { showCredentialsForm === null && (<div>
                            <h1>Connect Your Store</h1>
                            <p>Select your store platform</p>
                        </div>
                        )
                        || (
                            <div>
                            <h1>Connect Your {showCredentialsForm} Store</h1>
                            </div>
                        )
                    }
                    { showCredentialsForm === null && (
                        <div className="store-options">
                            <div onClick={() => setShowCredentialsForm("Shopify")} className="store-option"><img src={Shopify}/></div>
                            <div onClick={() => setShowCredentialsForm("WooCommerce")} className="store-option"><img src={WooCommerce}/></div>
                            <div style={{ display: "flex", flexDirection: 'column' }} onClick={() => {return; window.location.href = "https://auth.ebay.com/oauth2/authorize?state=64ac6c76b3ceb62b3cd99d90&prompt=login&client_id=dropship-DropShip-PRD-942a55cd8-b6439687&response_type=code&redirect_uri=drop-shipping.a-dropship-DropSh-imiain&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly"}} className="store-option"><img src={EBay}/>Coming Soon</div>
                            <div style={{ display: "flex", flexDirection: 'column' }} onClick={() => {return; window.location.href = "https://auth.ebay.com/oauth2/authorize?state=64ac6c76b3ceb62b3cd99d90&prompt=login&client_id=dropship-DropShip-PRD-942a55cd8-b6439687&response_type=code&redirect_uri=drop-shipping.a-dropship-DropSh-imiain&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly https://api.ebay.com/oauth/api_scope/commerce.notification.subscription https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly"}} className="store-option"><img src={Etsy}/>Coming Soon</div>
                        </div>
                        ) || (
                        <div className="form-group">
                            <label>Store Name</label>
                            <input
                                value={storeName}
                                placeholder={"My Shop"}
                                onChange={(e) => setStoreName(e.target.value)}
                            />
                            <label>Store Url</label>
                            <input
                                value={storeUrl}
                                placeholder={"https://my-shop.com"}
                                onChange={(e) => setStoreUrl(e.target.value)}
                            />
                            { showCredentialsForm === "Shopify" ?
                                <ShopifyCredentialsInput shopifyAdminToken={shopifyAdminToken} setShopifyAdminToken={setShopifyAdminToken}/> :
                                <></>}
                            { error && <p style={{ color: "red" }}>{error}</p>}

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "end", gap: 10 }}>
                                    { loading && <CircularProgress style={{ width: 45, height: 45 }} />}
                                    <img onClick={handleStoreRegistration} style={{ cursor: 'pointer', width: '150px', marginTop: 10 }} src={AddStore} width={"200px"}/>
                                </div>
                        </div>
                    ) }
                </div>
            </Modal>
        </div>
    );
};


function ShopifyCredentialsInput({shopifyAdminToken, setShopifyAdminToken}) {
    return (
        <>
            <label style={{ display: 'flex', alignItems: 'start', justifyContent: 'start', gap: 10 }}>Admin Token <Link style={{ display: 'flex', gap: 5 }} target="_blank" to="https://dropshippingai.tawk.help/article/how-to-connect-your-shopify-store"><CiCircleInfo size={"18"}/> Read the help guide</Link></label>
            <input
                type="shopifyAdminToken"
                value={shopifyAdminToken}
                placeholder={"shpat_********"}
                onChange={(e) => setShopifyAdminToken(e.target.value)}
            />
        </>
    )
}

export default StoresTableComponent;
