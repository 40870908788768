import React from "react";
import {Outlet} from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";

export default function MainLayout() {
  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh", backgroundColor: '#F8FAFF' }}>
      <Sidebar />
        <div className={`App-page`}>
        <Outlet />
      </div>
    </div>
  );
}
