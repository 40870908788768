import React, {useEffect, useState} from "react";
import {FaChartBar, FaFileAlt, FaSignOutAlt, FaStore} from "react-icons/fa";
import {NavLink} from "react-router-dom";
import Logo from "../../assets/icons/logo.png";
import {clearAuthToken} from '../../stores/Auth';
import {useDispatch} from 'react-redux';

import "./Sidebar.scss";

export default function Sidebar() {
    const dispatch = useDispatch();

    return (
        <div className={`sidebar`}>

          <div className="sidebar-container">
            <div className="sidebar-logo">
              <NavLink to="/dashboard">
               <img width="220px" src={Logo} alt="Logo" />
              </NavLink>
            </div>

            <div
              className="sidebar-tabs-container"
              style={{ maxHeight: "55vh" }}
            >
                <div className="sidebar-options">
                    <NavLink to="/dashboard" className="sidebar-option">
                        <FaChartBar color="black" />
                        <span>Dashboard</span>
                    </NavLink>
                </div>

              <div className="sidebar-options">
                <NavLink to="/orders" className="sidebar-option">
                  <FaFileAlt color="black" />
                  <span>Orders</span>
                </NavLink>
              </div>

                <div className="sidebar-options">
                    <NavLink to="/orders-v2" className="sidebar-option">
                        <FaFileAlt color="black" />
                        <span>Orders v2</span>
                    </NavLink>
                </div>

              <div className="sidebar-options">
                  <NavLink to="/stores" className="sidebar-option">
                    <FaStore color="black" />
                    <span>My Stores</span>
                  </NavLink>
              </div>

            </div>

              <span id={"logout"} onClick={() => dispatch(clearAuthToken())} >
                      <FaSignOutAlt color="white" />
                      <span>Logout</span>
                  </span>
            </div>
        </div>
      );
}
