import axios from "axios";
import {getAuthToken} from "../../stores/Auth";
import {isExpired} from "react-jwt";

class PlatformClient {
    constructor() {
        this.httpClient = axios.create({
            baseURL: 'https://app.buydhn.com',
            //baseURL: 'http://localhost:80',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        })

        this.httpClient.interceptors.request.use(
            (config) => {
                if (isExpired(getAuthToken())) {
                    window.location.href = "/login"
                }

                config.headers.Authorization = `Bearer ${getAuthToken()}`;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // You can also set up any additional configuration or interceptors for the axios instance here
    }



    async get(endpoint) {
        try {
            const response = await this.httpClient.get(endpoint);
            return response.data;
        } catch (error) {
            // Handle error
            throw error;
        }
    }

    async post(endpoint, data) {
        try {
            const response = await this.httpClient.post(endpoint, data);
            return response.data;
        } catch (error) {
            // Handle error
            throw error;
        }
    }

    async delete(endpoint, data) {
        try {
            const response = await this.httpClient.delete(endpoint, data);
            return response.data;
        } catch (error) {
            // Handle error
            throw error;
        }
    }
}

export default new PlatformClient();
