import "./style.scss"
import {CircularProgress} from "@mui/material";
import React, {useEffect} from "react";
import Done from "../../assets/images/done.png"
import Warning from "../../assets/images/warning.png"
import Error from "../../assets/images/error.png"
import {FaTrashCan} from "react-icons/fa6";

export default function JobsComponent({ removeJob, jobs }) {

    const icon = (job) => {
        if (job.successes?.length === job.productIds.length) {
            return <img src={Done} style={{ width: 15 }} />
        }

        if (job.failures?.length > 0 && job.successes > 0) {
            return <img src={Warning} style={{ width: 15 }} />
        }

        return <img src={Error} style={{ width: 15 }} />
    }
    
    jobs.splice(3);
    return <div className="jobs-container">
        {jobs.reverse().map(job => {
            return job.productIds !== null ? ( <div className="job">
                { job.status === "PROCESSING" && <p style={{ justifyContent: 'start', display: 'flex', gap: 10, alignItems: "center", width: '-webkit-fill-available' }}><CircularProgress style={{ width: 20, height: 20 }} /> {job.mode !== "restore" ? 'Optimization' : 'Restore'} in progress - {job.successes.length + job.failures.length} / {job.productIds.length}</p> }
                { job.status === "COMPLETED" && <p style={{ justifyContent: 'start', display: 'flex', gap: 10, alignItems: "center", width: '-webkit-fill-available' }}>{icon(job)} {!job.mode.includes("restore") ? 'Optimization' : 'Restore'} Finished - {job.successes.length + job.failures.length} / {job.productIds.length}</p> }
                { job.status === "COMPLETED" && <div style={{  width: '-webkit-fill-available', justifyContent: 'end', display: 'flex', gap: 10, alignItems: "center" }}>
                    <FaTrashCan onClick={() => removeJob(job.id)} style={{ alignSelf: 'end', cursor: 'pointer' }}/>
                </div>}
            </div> ) : (
                <div className="job">
                    <p style={{ justifyContent: 'start', display: 'flex', gap: 10, alignItems: "center", width: '-webkit-fill-available' }}><CircularProgress style={{ width: 20, height: 20 }} /> {!job.mode.includes("restore") ? 'Optimization' : 'Restore'} in progress - {job.successes.length + job.failures.length} / many</p>
                </div>
            )
            })
        }
    </div>
}
