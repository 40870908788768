import React, {useEffect, useState} from 'react';
import {
    IconButton,
    InputAdornment,
    MenuItem,
    Modal,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import PLatformClient from "../../components/client/Client";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LoopIcon from '@mui/icons-material/Loop';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import AmazonIcon from '../../assets/icons/amazon.png'
import Walmart from '../../assets/icons/walmart.png'

import './style.scss';
import OptimizationModal from "../productOptimizationModal";
import {Close, Search} from "@mui/icons-material";
import CustomPagination from "../pagination/CustomPagination";

const TableComponent = ({ itemsInProgress, checked, setChecked, selectedFilter, setSelectedFilter, setJobs, page, setPage, data, stores, prevPageInfo, nextPageInfo, setPageInfo, selectedStoreId, setSelectedStoreId, credits, setSearch }) => {
    const [filterText, setFilterText] = useState("");
    const [expanded, setExpanded] = useState(null);
    const [modalFor, setModalFor] = useState(null)
    const [order, setOrder] = useState(null);
    const [orderForManual, setOrderForManual] = useState(null)
    const [addShippingAdderss, setAddShippingAdderss] = useState(null)
    const [shippingAddress, setShippingAdderss] = useState(null)

    const handleExpand = (item) => {
        setExpanded(item);
    };

    const handleCloseExpand = () => {
        setExpanded(null);
    };

    const handleFilterChange = () => {
        setSearch(filterText)
    };

    const truncate = (str, truncate = 10) => {
        return str.substring(0,truncate) + '...';
    }

    const handleChangePage = (newPage) => {
        if (prevPageInfo && nextPageInfo) {
            setPageInfo(newPage > page ? nextPageInfo : prevPageInfo);
            setPage(newPage);
        } else {
            setPage(newPage);
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        data = []
    }, [selectedStoreId])

    async function doAction(item, status) {
        if (status === "PENDING") {
            setOrder(item);
            setIsModalOpen(true)
        }

        if (status === "CANCELED") {
            await PLatformClient.post("/orders/cancel?orderId=" + item.id.split("-")[1] + "&storeId=" + selectedStoreId,  {});
            document.location.reload();
        }

        if (status === "ORDER_REQUESTED") {
            const ShopifyStore = stores.filter(s => s?.id === selectedStoreId)[0].url.split("https://")[1].split(".myshopify")[0]
            await PLatformClient.post("/orders/status",  { status: 'ORDER_REQUESTED', shopifyOrderId: item.id, shopifyStore: ShopifyStore, shopifyItemSku: item.lineItem.variant.sku, externalItemSku: item.externalItemSku, buyPrice: item.buyPrice, sellPrice: item.lineItem.variant.price  });
            document.location.reload();
        }

        if (status === "ORDERED") {
            setOrderForManual(item);
        }
    }

    console.log(orderForManual)
    function enrichStatus(item) {
        if (item.financialStatus === 'VOIDED' || item.financialStatus === 'REFUNDED') {
            return 'CANCELED'
        }

        if (item.fulfillmentStatus === 'FULFILLED' || item.fulfillmentStatus === 'PARTIALLY_FULFILLED') {
            return 'SHIPPED'
        }

        return item.status;
    }

    function getColorForStatus(enrichStatus) {
        switch (enrichStatus) {
            case "CANCELED":
                return "#f2f2f2";
            case "SHIPPED":
                return "#68a2e4";
            case "MISSING_PRODUCT":
                return "#fed02f";
            case "PENDING":
                return "#fed02f";
            case "ORDERED":
                return "#cbe2ff";
            case "DELIVERED":
                return "#cbe2ff";
        }
    }

    return (
        <div className="table-container">
            <div className="filter-sort-container">
                <Select
                    sx={{ width: '30%', height: '100%', marginTop: 0.5 }}
                    value={selectedStoreId}
                    onChange={(e) => setSelectedStoreId(e.target.value)}
                    label="">
                    {stores?.map(store => {
                        return <MenuItem value={store.id}>{store.name}</MenuItem>
                    })}
                </Select>

                <TextField
                    className="search"
                    placeholder="Search for anything..."
                    onChange={(e) => setFilterText(e.target.value)}
                    value={filterText}
                    size={"small"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Search style={{ cursor: 'pointer' }} onClick={handleFilterChange} />
                            </InputAdornment>
                        ),
                    }}
                />

            </div>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Item ID</TableCell>
                            <TableCell>QTY</TableCell>
                            <TableCell>DAS</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Profit</TableCell>
                            <TableCell>Order ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.orders?.map((item) => (
                            <TableRow key={item.id} className={itemsInProgress.includes(item.id) ? "in-progress" : ""}>
                                <TableCell>
                                    <div style={{ display: 'flex', gap: '25px' }}>
                                        <div style={{ width: 65, height: 65, display: 'flex', justifyContent: 'center' }}>
                                            <img className="product-image" src={item.lineItem.variant.image} alt="Item" />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px', justifyContent: 'center', width: 200}}>
                                            <span><b style={{ fontSize: 11, fontWeight: '700' }}>{truncate(item.lineItem.title, 65)}</b></span>
                                            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
                                                { item.risk && <WarningIcon color={"error"}/> }
                                                <span>{item.lineItem.variant.title}</span>
                                            </div>
                                        </div>
                                    </div>

                                </TableCell>
                                <TableCell><p style={{ width: 75 }}>{formatDate(item.date)}</p></TableCell>
                                <TableCell>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="status-select"
                                        sx={{ backgroundColor: getColorForStatus(enrichStatus(item)), width: 180,  cursor: 'pointer', whiteSpace: 'nowrap', fontWeight: 700, fontSize: 12  }}
                                        value={enrichStatus(item)}
                                        onChange={(e) => doAction(item, e.target.value)}
                                    >
                                        <MenuItem style={{ height: 40, display: 'flex', gap: 10 }} value={"MISSING_PRODUCT"}><LinkOffIcon fontSize={"small"}/> Missing Product</MenuItem>
                                        <MenuItem style={{ height: 40, display: 'flex', gap: 10 }} value={"PENDING"}><PendingActionsIcon fontSize={"small"}/> Pending</MenuItem>
                                        <MenuItem disabled={item.status === "MISSING_PRODUCT"} style={{ height: 40, display: 'flex', gap: 10 }} value={"ORDER_REQUESTED"}><LoopIcon fontSize={"small"}/> Order Requested</MenuItem>
                                        <MenuItem disabled={item.status} style={{ height: 40, display: 'flex', gap: 10 }} value={"PROCESSING"}><LoopIcon fontSize={"small"}/> Processing</MenuItem>
                                        <MenuItem style={{ height: 40, display: 'flex', gap: 10 }} value={"CANCELED"}><CancelIcon fontSize={"small"}/> Canceled</MenuItem>
                                        <MenuItem style={{ height: 40, display: 'flex', gap: 10 }} value={"ORDERED"}><CreditScoreIcon fontSize={"small"}/> <div style={{ display: 'flex', flexDirection: 'column' }}> Ordered<span>{item.eta}</span></div></MenuItem>
                                        <MenuItem disabled={item.status === "MISSING_PRODUCT"} style={{ height: 40, display: 'flex', gap: 10 }} value={"SHIPPED"}><LocalShippingIcon fontSize={"small"}/> Shipped</MenuItem>
                                        <MenuItem disabled={item.status === "MISSING_PRODUCT"} style={{ height: 40, display: 'flex', gap: 10 }} value={"DELIVERED"}><CheckCircleIcon fontSize={"small"}/> Delivered</MenuItem>
                                    </Select>
                                </TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                         <span style={{ marginRight: '8px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <p style={{ backgroundColor: "rgb(242, 242, 242)", borderRadius: '2px', fontSize: '10px', maxWidth: '50px', color: 'rgb(90, 88, 89)', padding: '5px' }}>BUY: </p><p style={{ display: 'flex', gap: item.platform === "AMAZON" ? 8 : 0, alignItems: 'center' }}>{ item.platform &&  <img width={item.platform === "AMAZON" ? 13 : 30} src={item.platform === "AMAZON" ? AmazonIcon : Walmart}/>}{item.lineItem.variant.sku}</p>
                                          </span>
                                            <span style={{ marginRight: '8px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <p style={{ backgroundColor: "rgb(242, 242, 242)", borderRadius: '2px', fontSize: '10px', maxWidth: '50px', color: 'rgb(90, 88, 89)', padding: '5px' }}>SELL: </p>{item.lineItem.productId}
                                            </span>
                                        { item.tripleMarsOrderId && <span style={{ marginRight: '8px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <p style={{ backgroundColor: "rgb(242, 242, 242)", borderRadius: '2px', fontSize: '10px', maxWidth: '50px', color: 'rgb(90, 88, 89)', padding: '5px' }}>TM: </p>{item.tripleMarsOrderId}
                                            </span> }

                                    </div>
                                </TableCell>
                                <TableCell style={{ width: 10 }}>{item.lineItem.quantity}</TableCell>
                                <TableCell style={{ width: 10 }}>{calculateDaysPassed(item.date)}</TableCell>
                                <TableCell><p style={{ width: 100 }}>{item.customerName}</p></TableCell>
                                <TableCell><p style={{ width: 100 }}>{formatShippingAddress(item, setAddShippingAdderss)}</p></TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                         <span style={{ marginRight: '8px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <p style={{ backgroundColor: "rgb(242, 242, 242)", borderRadius: '2px', fontSize: '10px', maxWidth: '50px', color: 'rgb(90, 88, 89)', padding: '5px' }}>BUY: </p>{item.buyPrice > 0 ? "$" + item.buyPrice.toFixed(2) : "-"}
                                          </span>
                                        <span style={{ marginRight: '8px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <p style={{ backgroundColor: "rgb(242, 242, 242)", borderRadius: '2px', fontSize: '10px', maxWidth: '50px', color: 'rgb(90, 88, 89)', padding: '5px' }}>SELL: </p>${item.lineItem.variant.price.toFixed(2)}
                                          </span>
                                    </div>
                                </TableCell>
                                <TableCell>{item.profit > 0 ? "$" + (item.profit).toFixed(2) : "-"}</TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                         <span style={{ marginRight: '8px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <p style={{ backgroundColor: "rgb(242, 242, 242)", borderRadius: '2px', fontSize: '10px', maxWidth: '50px', color: 'rgb(90, 88, 89)', padding: '5px' }}>BUY: </p>{item?.externalOrderId ? truncate(item.externalOrderId, 15) : "-"}
                                          </span>
                                        <span style={{ whiteSpace: 'nowrap', marginRight: '8px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <p style={{ backgroundColor: "rgb(242, 242, 242)", borderRadius: '2px', fontSize: '10px', maxWidth: '50px', color: 'rgb(90, 88, 89)', padding: '5px' }}>SELL: </p>{truncate(item.id, 15)}
                                          </span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <CustomPagination totalCount={data?.count} itemsPerPage={10} onPageChange={handleChangePage} />
            </TableContainer>

            <Modal open={orderForManual} onClose={() => setOrderForManual(null)}>
                <div className="modal-container">
                    <IconButton
                        className="close-icon"
                        onClick={() => setOrderForManual(null)}
                        size="small"
                    >
                        <Close />
                    </IconButton>

                    <h2 style={{ margin: 0 }}>Add Manual Order</h2>
                    <div style={{ display: 'flex', gap: 50, width: 800, textAlign:'start' }}>
                        <form>
                            <div className="form-group">
                                <label>External Item ID</label>
                                <input
                                    type="text"
                                    value={orderForManual?.externalItemSku}
                                    onChange={(e) => { setOrderForManual({ ...orderForManual, ...{ externalItemSku: e.target.value }  }) }}
                                />
                            </div>
                            <div className="form-group">
                                <label>External Order ID</label>
                                <input
                                    type="text"
                                    value={orderForManual?.externalOrderId}
                                    onChange={(e) => { setOrderForManual({ ...orderForManual, ...{ externalOrderId: e.target.value }  }) }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Buy Price</label>
                                <input
                                    type="text"
                                    value={orderForManual?.buyPrice}
                                    onChange={(e) => { setOrderForManual({ ...orderForManual, ...{ buyPrice: e.target.value }  }) }}
                                />
                            </div>
                            <div className="form-group">
                                <label>Platform</label>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="status-select"
                                    sx={{ width: 180,  cursor: 'pointer', whiteSpace: 'nowrap', fontWeight: 700, fontSize: 12  }}
                                    value={orderForManual?.platform}
                                    onChange={(e) => { setOrderForManual({ ...orderForManual, ...{ platform: e.target.value }  }) }}
                                >
                                    <MenuItem style={{ height: 40, display: 'flex', gap: 10 }} value={"AMAZON"}>AMAZON</MenuItem>
                                    <MenuItem style={{ height: 40, display: 'flex', gap: 10 }} value={"WALMART"}>WALMART</MenuItem>
                                </Select>
                            </div>

                            <button className="buy-now-button" onClick={async () => {
                                const newOrder = {
                                    externalOrderId: orderForManual.externalOrderId,
                                    shopifyOrderId: orderForManual.id,
                                    externalItemSku: orderForManual.externalItemSku,
                                    shopifyStore: selectedStoreId,
                                    shopifyItemSku: orderForManual.lineItem.variant.sku,
                                    buyPrice: orderForManual.buyPrice,
                                    sellPrice: orderForManual.lineItem.variant.price,
                                    status: "ORDERED",
                                    date: new Date(),
                                    platform: orderForManual.platform
                                }
                                //console.log(orderForManual)
                                await PLatformClient.post("/order", newOrder);
                                document.location.reload();
                            }}>SAVE</button>
                        </form>
                    </div>
                </div>
            </Modal>
            <Modal open={isModalOpen} onClose={() => handleCloseModal()}>
                <div className="modal-container">
                    <IconButton
                        className="close-icon"
                        onClick={() => handleCloseModal()}
                        size="small"
                    >
                        <Close />
                    </IconButton>

                    <h2 style={{ margin: 0 }}>Add Product</h2>
                    <form>
                        <div className="form-group">
                            <label>External Item ID</label>
                            <input
                                type="text"
                                value={order?.shopifyItemSku}
                                onChange={(e) => { setOrder({ ...order, ...{ externalItemSku: e.target.value }  }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Buy Price</label>
                            <input
                                type="text"
                                value={order?.buyPrice}
                                onChange={(e) => { setOrder({ ...order, ...{ buyPrice: e.target.value }  }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Platform</label>
                            <Select
                                labelId="demo-simple-select-label"
                                id="status-select"
                                sx={{ width: 180,  cursor: 'pointer', whiteSpace: 'nowrap', fontWeight: 700, fontSize: 12  }}
                                value={order?.platform}
                                onChange={(e) => { setOrder({ ...order, ...{ platform: e.target.value }  }) }}
                            >
                                <MenuItem style={{ height: 40, display: 'flex', gap: 10 }} value={"AMAZON"}>AMAZON</MenuItem>
                                <MenuItem style={{ height: 40, display: 'flex', gap: 10 }} value={"WALMART"}>WALMART</MenuItem>
                            </Select>
                        </div>

                    </form>
                    <button onClick={async () => {
                        const ShopifyStore = stores.filter(s => s.id === selectedStoreId)[0].url.split("https://")[1].split(".myshopify")[0]
                        await PLatformClient.post("/orders/addProduct",  { shopifyOrderId: order.id, shopifyStore: ShopifyStore, shopifyItemSku: order.lineItem.variant.sku, externalItemSku: order.externalItemSku, buyPrice: order.buyPrice, sellPrice: order.lineItem.variant.price  });
                        document.location.reload();
                    }} className="buy-now-button">Save</button>
                </div>
            </Modal>

            <Modal open={addShippingAdderss} onClose={() => setAddShippingAdderss(null)}>
                <div className="modal-container">
                    <IconButton
                        className="close-icon"
                        onClick={() => setAddShippingAdderss(null)}
                        size="small"
                    >
                        <Close />
                    </IconButton>

                    <h2 style={{ margin: 0 }}>Add Shipping Address</h2>
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                value={shippingAddress?.name}
                                onChange={(e) => { setShippingAdderss({ ...shippingAddress, name: e.target.value  }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Address1</label>
                            <input
                                type="text"
                                value={shippingAddress?.address1}
                                onChange={(e) => { setShippingAdderss({ ...shippingAddress, address1: e.target.value  }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Address2</label>
                            <input
                                type="text"
                                value={shippingAddress?.address2}
                                onChange={(e) => { setShippingAdderss({ ...shippingAddress, address2: e.target.value  }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                value={shippingAddress?.city}
                                onChange={(e) => { setShippingAdderss({ ...shippingAddress, city: e.target.value  }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Zip</label>
                            <input
                                type="text"
                                value={shippingAddress?.zip}
                                onChange={(e) => { setShippingAdderss({ ...shippingAddress, zip: e.target.value  }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Province</label>
                            <input
                                type="text"
                                value={shippingAddress?.province}
                                onChange={(e) => { setShippingAdderss({ ...shippingAddress, province: e.target.value  }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label>Country Code</label>
                            <input
                                type="text"
                                value={shippingAddress?.countryCode || "US"}
                                onChange={(e) => { setShippingAdderss({ ...shippingAddress, countryCode: e.target.value  }) }}
                            />
                        </div>




                    </form>
                    <button onClick={async () => {
                        const ShopifyStore = stores.filter(s => s?.id === selectedStoreId)[0].url.split("https://")[1].split(".myshopify")[0]
                        await PLatformClient.post("/orders/addShippingAddress",  { shopifyOrderId: addShippingAdderss.id, shopifyStore: ShopifyStore, shopifyItemSku: addShippingAdderss.lineItem.variant.sku, shippingAddress  });
                        document.location.reload();
                    }} className="buy-now-button">Save</button>
                </div>
            </Modal>

            <Modal open={expanded !== null} onClose={() => handleCloseExpand()}>
                <div className="modal-container">
                    <IconButton
                        className="close-icon"
                        onClick={() => setExpanded(null)}
                        size="small"
                    >
                        <Close />
                    </IconButton>
                    <h3>{expanded?.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: expanded?.description }} />
                </div>
            </Modal>
        </div>
    );
};

function formatDate(dateString) {
    // Create a new Date object from the dateString
    const date = new Date(dateString);

    // Define months array for mapping month indexes to month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract day, month, and year from the date object
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Format the date as "MMM DD, YYYY"
    const formattedDate = `${months[monthIndex]} ${day}, ${year}`;

    return formattedDate;
}

function calculateDaysPassed(dateString) {
    // Convert the dateString to a Date object
    const date = new Date(dateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds between the current date and the provided date
    const differenceInMs = currentDate - date;

    // Convert milliseconds to days
    const daysPassed = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

    return daysPassed;
}

function formatShippingAddress(item, setAddShippingAdderss) {
    const { country, countryCode, city, address1 } = item.shippingDetails;
    if (address1 === "null") {
        return (
            <button onClick={() => setAddShippingAdderss({ ...item, countryCode: "US" })} style={{ width: 100, height: 100 }}>Add Shipping Address</button>
        )
    }
    return `${country ? country : countryCode}, ${city}, ${address1}`;
}

function convertToTitleCase(str) {
    // Split the string by underscores (_) and join with spaces
    const words = str.split('_').join(' ');

    // Convert the first letter of each word to uppercase and the rest to lowercase
    const titleCaseWords = words.toLowerCase().split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the title case words back into a string
    const titleCaseString = titleCaseWords.join(' ');

    return titleCaseString;
}

export default TableComponent;
