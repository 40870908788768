import React, {useEffect, useState} from 'react';
import './index.scss';
import Done from "../../assets/images/v.png"
import Star from "../../assets/images/star.png"
import PLatformClient from "../../components/client/Client";
import Disconnect from "../../assets/images/disconnect.png";
import {FaDownload} from "react-icons/fa";

function BillingPage() {
    const [ paymentLink, setPaymentLink ] = useState(null)
    const [ coupon, setCoupon ] = useState(null)
    const [ couponApplied, setCouponApplied ] = useState(-1);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null)
    const [invoicesData, setInvoicesData] = useState(null)

    const onPick = async (plan) => {
        const res = await PLatformClient.get("/payment/" + plan);
        setPaymentLink(res?.paymentLink)
        //window.location.href = res?.paymentLink;
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await PLatformClient.get('/dashboard');
            let no = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(response?.products - response?.optimized)
            setData(no)
            const invoices = await PLatformClient.get('/billing');
            setInvoicesData(invoices);
        };

        fetchData();
    }, []);

    return (
         <div className="App-page-content billing-page">
             <div id="pricing" className="billing-section">
                 <h1>Unleash The Power Of Your Store With AI</h1>

                 { paymentLink && <iframe style={{ border: 'none', height: '80vh' }} src={paymentLink}></iframe>}

                 { !paymentLink && data && (
                     <>
                     <h4>You have {data} products pending edits.</h4>
                     <div className="billing-plans">
                     <div className="billing-plan">
                         <span className="plan-name">Basic</span>
                         <div className="plan-price">
                             <span className="currency">$</span>
                             <span className="amount">49</span>
                         </div>
                         <span></span>
                         <ul>
                             <li><span className="plan-desc"><img src={Done}/>One Time Payment</span></li>
                             <li><span className="plan-desc"><img src={Done}/>1,000 AI Credits</span></li>
                             <li><span className="plan-desc"><img src={Done}/>365 Days Backup</span></li>
                         </ul>
                         <button onClick={() => onPick("BASIC")} className="buy-button">BUY NOW</button>
                     </div>

                     <div className="billing-plan">
                         <span className="plan-name">Advanced</span>
                         <div className="plan-price">
                             <span className="currency">$</span>
                             <span className="amount">149</span>
                             <span className="original">$499</span>
                         </div>
                         <span>(You Save $350)</span>
                         <ul>
                             <li><span className="plan-desc"><img src={Done}/>One Time Payment</span></li>
                             <li><span className="plan-desc"><img src={Done}/>10,000 AI Credits</span></li>
                             <li><span className="plan-desc"><img src={Done}/>365 Days Backup</span></li>
                         </ul>
                         <button onClick={() => onPick("ADVANCED")} className="buy-button">BUY NOW</button>
                     </div>

                     <div className="billing-plan professional">
                         <label className="popular-label"><span><img src={Star}/>Most Popular</span></label>
                         <span className="plan-name">Professional</span>
                         <div className="plan-price">
                             <span className="currency">$</span>
                             <span className="amount">299</span>
                             <span className="original">$524</span>
                         </div>
                         <span>(You Save $225)</span>
                         <ul>
                             <li><span className="plan-desc"><img src={Done}/>One Time Payment</span></li>
                             <li><span className="plan-desc"><img src={Done}/>35,000 AI Credits</span></li>
                             <li><span className="plan-desc"><img src={Done}/>365 Days Backup</span></li>
                         </ul>
                         <button onClick={() => onPick("PROFESSIONAL")} className="buy-button">BUY NOW</button>
                     </div>

                     <div className="billing-plan">
                         <span className="plan-name">Business</span>
                         <div className="plan-price">
                             <span className="currency">$</span>
                             <span className="amount">689</span>
                             <span className="original">$689</span>
                         </div>
                         <span>(You Save $151)</span>
                         <ul>
                             <li><span className="plan-desc"><img src={Done}/>One Time Payment</span></li>
                             <li><span className="plan-desc"><img src={Done}/>100,000 AI Credits</span></li>
                             <li><span className="plan-desc"><img src={Done}/>365 Days Backup</span></li>
                         </ul>
                         <button onClick={() => onPick("BUSINESS")} className="buy-button">BUY NOW</button>
                     </div>
                 </div></> )}

                 { couponApplied <= 0 && <div style={{alignSelf: 'center', width: '15vw', height: 200, display: 'flex', flexDirection: 'column', gap: 15 }}>
                     <p>Got a coupon?</p>
                     <input
                         style={{ textAlign: 'center' }}
                         value={coupon}
                         placeholder={"Enter your coupon here"}
                         onChange={(e) => setCoupon(e.target.value)}
                     />
                     { couponApplied === 0 && <p>Coupon doesn't exist or invalid</p> }
                     <button style={{  minHeight: 40, cursor: "pointer" }} onClick={async () => {
                         if (coupon) {
                             const res = await PLatformClient.post('/payment/coupon?coupon=' + coupon.toUpperCase(), {});
                             setCouponApplied(res.credits);
                         }
                     }}>Redeem</button>
                 </div> }

                 { couponApplied > 0 &&
                     <div style={{alignSelf: 'center', width: '15vw', height: 200, display: 'flex', flexDirection: 'column', gap: 15 }}>
                         <p>You have recieved {couponApplied} credits.</p>
                     </div>

                 }

                 { invoicesData?.invoices?.length > 0 && (
                     <div style={{ textAlign: 'start', width: '100%' }}>
                         <h2>Payment History</h2>

                         <div className="invoices-container">
                             <div style={{ border: 'none' }} className="invoice-container">
                                 <span>Date</span>
                                 <span>ID</span>
                                 <span>Amount</span>
                                 <span>Invoice</span>
                             </div>
                             {invoicesData?.invoices?.map((item) => (
                                 <div className="invoice-container">
                                     <span style={{ fontSize: 18, display: 'inline-block', textAlign: 'center' }}>{item.date}</span>
                                     <span style={{ fontSize: 18, display: 'inline-block', textAlign: 'center' }}>{item.id}</span>
                                     <span style={{ fontSize: 18, display: 'inline-block', textAlign: 'center' }}>{item.amount} USD</span>
                                     <FaDownload style={{ cursor: 'pointer' }} onClick={() => window.open(item.link, '_blank')}/>
                                 </div>
                             ))}
                         </div>

                     </div>
                 ) }
             </div>
        </div>
    );
}

export default BillingPage;
