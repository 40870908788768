import React, {useEffect, useState} from 'react';
import './index.scss';
import StoresTableComponent from "../../components/storesTables";
import PLatformClient from "../../components/client/Client";
import {Skeleton} from "@mui/material";

function StoresPage() {

    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            const response = await PLatformClient.get('/stores');
            setStores(response)
            setLoading(false)
        };

        fetchData();
    }, []);


    return (
        <div className="App-page-content">
            <h1 className="page-title">My Stores</h1>
            <div className="page-inner-content">
                { loading &&   <>
                    <Skeleton animation="wave" variant="rounded" height={80} style={{ borderRadius: '16px'  }} />
                    <Skeleton variant="rounded" height={80} style={{ borderTopLeftRadius: 16, borderTopRightRadius: 16, marginTop: 30  }} />
                    <Skeleton animation="wave" variant="rounded" height={120} style={{   }} />
                    <Skeleton animation="wave" variant="rounded" height={120} style={{   }} />
                    <Skeleton animation="wave" variant="rounded" height={120} style={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16  }} />
                    </> }
                { !loading && <StoresTableComponent stores={stores} setStores={setStores}/>}
            </div>
        </div>
    );
}

export default StoresPage;
