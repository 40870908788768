import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {PersistGate} from 'redux-persist/integration/react';
import {persistor, store} from './stores/Auth.jsx'; // Import the modified store


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GoogleOAuthProvider
        clientId="1071465033408-utimtt2smcnc26uvvm3uuirh4vsio295.apps.googleusercontent.com"
        responseType="code"
        accessType="offline"
        scope="email profile"
    >
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
            <App />
            </PersistGate>
        </Provider>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
