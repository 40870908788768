import React from 'react';
import './index.scss';

function MobilePage() {

    return (
         <div style={{textAlign: 'center' }} className="App-page-content billing-page">
            <p>Drop-Shipping.AI Platform is currently working on Desktop.</p>
             <p>Please login to your account on your desktop to continue.</p>
        </div>
    );
}

export default MobilePage;
