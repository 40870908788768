import "./style.scss"

export default function DashboardCard({data}) {
    if (data.title === "Income Over Time") {

    }

    return (
        <div className={`dash-card v${data.v}`}>
            <svg style={{ position: "absolute", left: 0, top: 0 }} width="93" height="72" viewBox="0 0 93 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M92.6484 0C91.4757 12.1148 84.2892 46.1826 54.6837 61.6358C25.9606 76.6284 7.03835 71.4729 0.00128789 66.6271L0.00128359 20C0.00128257 8.95431 8.95559 6.92359e-06 20.0013 6.00982e-06L92.6484 0Z" fill="#D1B8FF"/>
            </svg>
            <h3>{data.title}</h3>
            { data.title !== "Income Over Time" &&  <p>{data.insight}</p>}
            <svg style={{ position: "absolute", right: 0, bottom: 0 }} width="157" height="141" viewBox="0 0 157 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M156.762 0.751678V121C156.762 132.046 147.808 141 136.762 141L0.824219 141C7.01225 114.7 32.8606 62.1001 86.7495 62.1001C140.622 62.1001 155.87 21.2133 156.762 0.751678Z" fill="#D1B8FF"/>
            </svg>
            { data.title !== "Income Over Time" && <h3>{data.value}</h3>}
            { data.title === "Income Over Time" &&  <p>{data.insight}</p>}

            {/* data.progress != null && <CircularProgressBar progress={data.progress} /> }
            {/* data.cta === 'start_ai' && <img src={StartAIImage}/>}
            { data.cta === 'connect_store' && <img src={StoresImage}/>*/}
            {/*<button style={{ cursor: 'pointer' }} onClick={() => window.location.href = "/product-optimizer"} className="dash-cta-button">Optimize Now</button>*/}
        </div>
    )
}