import React from "react";
import Logo from "../../assets/icons/logo.png";
import LoginBG from "../../assets/images/login_bg.png";

import "./Welcome.scss";

function Welcome() {
  return (
    <div
      className={"auth-welcome auth-welcome-50-width"}
    >
      <div className="auth-welcome-wrapper">
        <div className="auth-welcome-content">
          <h1 className="auth-welcome-title">Welcome</h1>
          <img style={{ marginTop: "20px" }} src={Logo} width={"150px"} />
        </div>
        <div className="auth-welcome-content-small">
          <img src={LoginBG}  width={"336px"}  />
          <div className="auth-welcome-company">© DHN LLC. 2024</div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
