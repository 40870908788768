import Welcome from "../../components/welcome/Welcome";
import "./style.scss";
import {NavLink} from "react-router-dom";
import React, { useEffect } from 'react';

export default function RegistrationComplete() {

    useEffect(() => {
        window?.gtag('event', 'conversion', {'send_to': 'AW-11335683476/kLLHCNjj_-MYEJSbo50q'});
    }, []);

    return (
    <div className="login-page">
      <Welcome />
      <div className="signin-container">
          <h4>Your'e In!</h4>
          <p>Thank you for choosing Drop-Shipping.AI</p>
          <p>The platform is currently supported for Desktop devices only.</p>
          <div className="signup">
              <span>It's time to log into the platform</span>
              <NavLink to="/login" className="signup-link">
                  <span>Login</span>
              </NavLink>
          </div>
      </div>

    </div>
  );
}
