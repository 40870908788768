import React, {useState} from "react";
import Welcome from "../../components/welcome/Welcome";
import "./style.scss";
import {setAuthToken} from '../../stores/Auth.jsx';
import {useDispatch} from 'react-redux';
import {GoogleLogin} from '@react-oauth/google';
import {NavLink} from "react-router-dom";
import axios from "axios";
import PLatformClient from "../../components/client/Client";

export default function Signup() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showVerification, setShowVerification] = useState(false);

    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };

    const handleSignup = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidUsername = emailPattern.test(username);
        if (!isValidUsername) {
            setError("Email is invalid.")
            return
        }

        axios
            .post('https://api.drop-shipping.ai/signup', { username, password })
            //.post('http://localhost:8080/signup', { username, password })
            .then(response => {
                setShowVerification(true);
            })
            .catch(error => {
                setError(error.response.data.message);
            });
    };

    function onGoogleLogin(credentialResponse) {
        axios
            .post('https://api.drop-shipping.ai/signup', { gidToken: credentialResponse.credential })
            //.post('http://localhost:8080/signup', { gidToken: credentialResponse.credential })
            .then(async response => {
                window.location.href = "/registration-complete"
            })
            .catch(error => {
                setError(error.response.data.message);
            });
    }

    return (
    <div className="login-page">
      <Welcome />
        { !showVerification &&  (<div className="signin-container">
          <h4>SIGN UP</h4>
          <GoogleLogin width="320px" text="signup_with" onSuccess={onGoogleLogin} onError={() => { console.log('Login Failed'); }} />
          <div id="or">OR</div>
          <form>
              <div className="form-group">
                <label>* Email</label>
                <input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>* Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', verticalAlign: 'middle', textAlign: 'center' }}>



              </div>
              <button type="button" onClick={handleSignup}>
                Signup
              </button>
              { error && <span className="error-msg">* {error}</span>}
            </form>
              <div className="signup">
                  <span>Already have an account?</span>
                  <NavLink to="/login" className="signup-link">
                      <span>Login</span>
                  </NavLink>
              </div>
        </div>) || (<div style={{ textAlign: 'center' }} className="signin-container">
            <h4 style={{ fontSize: 24 }}>Please verify you email</h4>
            <p>Your'e almost there!<br/> We sent an email to {username}</p>
            <p>Just click on the link in that email to complete your signup.</p>
            <p>If you don't see it, you may need to <b>check you spam</b> folder</p>
        </div>)}

    </div>
  );
}
