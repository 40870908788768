import React, {useState} from "react";
import Welcome from "../../components/welcome/Welcome";
import "./style.scss";
import {setAuthToken} from '../../stores/Auth.jsx';
import {useDispatch} from 'react-redux';
import {GoogleLogin} from '@react-oauth/google';
import {NavLink} from "react-router-dom";
import axios from "axios";
import PLatformClient from "../../components/client/Client";

export default function Login() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');


    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };

    const handleSignIn = async () => {
        axios
            .post('https://app.buydhn.com/authenticate', { username, password })
            //.post('http://localhost:80/authenticate', { username, password })
            .then(async (response) => {
                console.log("response", response)
                dispatch(setAuthToken(response.data?.token));
                window.location.href = "/dashboard"
            })
            .catch(error => {
                setError(error.response.data);
            });
    };

    function onGoogleLogin(credentialResponse) {
        axios
            .post('https://api.drop-shipping.ai/authenticate', { gidToken: credentialResponse.credential })
            //.post('http://localhost:8080/authenticate', { gidToken: credentialResponse.credential })
            .then(async response => {
                dispatch(setAuthToken(response.data?.token));
                const res = await PLatformClient.get('/stores');
                if (res.length > 0) {
                    window.location.href = "/dashboard"
                } else {
                    window.location.href = "/stores"
                }
            })
            .catch(error => {
                setError(error.response.data.message);
            });
    }


    return (
    <div className="login-page">
      <Welcome />
      <div className="signin-container">
          <h4>LOG IN</h4>
          <form>
              <div className="form-group">
                <label>* Email</label>
                <input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>* Password</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', verticalAlign: 'middle', textAlign: 'center' }}>
              </div>
              <button type="button" onClick={handleSignIn}>
                Login
              </button>
            </form>

      </div>

    </div>
  );
}
