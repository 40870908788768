import React, {useEffect, useState} from 'react';
import './index.scss';
import TableComponent from "../../components/productsTitleTables";
import PLatformClient from "../../components/client/Client";
import {Grid, Skeleton} from "@mui/material";
import JobsComponent from "../../components/jobs";
import DashboardCard from "../../components/dashboardCard";

function ProductOptimizerPage() {
    const [jobs, setJobs] = useState([])
    const [itemsInProgress, setItemsInProgress] = useState([])

    const [credits, setCredits] = useState(null)
    const [selectedStoreId, setSelectedStoreId] = useState(null);
    const [stores, setStores] = useState([])
    const [ordersCount, setOrdersCount] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log(stores.length)
                if (stores.length === 0) {
                    const storesResponse = await PLatformClient.get('/stores');
                    console.log(storesResponse)

                    setStores(storesResponse.filter(s => s.name !== "SHANULKA Home Decor"))
                    if (localStorage.getItem("store") !== null) {
                        setSelectedStoreId(localStorage.getItem("store"))
                    } else {
                        setSelectedStoreId(storesResponse?.length > 0 ? storesResponse[0].id : null)
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (selectedStoreId !== null) {
            localStorage.setItem("store", String(selectedStoreId))
        }
    }, [selectedStoreId])


    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersResponse = await PLatformClient.get('/orders/count');
                setOrdersCount(ordersResponse)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const [data, setData] = useState(undefined);
    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1);
    const [selectedFilter, setSelectedFilter] = useState("TITLES");
    const [checked, setChecked] = useState([])

    // Used for Shopify pagination
    const [nextPageInfo, setNextPageInfo] = useState(null);
    const [prevPageInfo, setPrevPageInfo] = useState(null);
    const [pageInfo, setPageInfo] = useState(null);

    const fetchProducts = async (url) => {
        try {
            const response = await PLatformClient.get(url);
            setData(response);
            setNextPageInfo(encodeURIComponent(response?.nextPageInfo));
            setPrevPageInfo(encodeURIComponent(response?.prevPageInfo));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (selectedStoreId) {
            setData([]);
            fetchProducts(`/orders?storeId=${selectedStoreId}&filter=${selectedFilter}`);
        }
    }, [selectedStoreId]);

    useEffect(() => {
        setNextPageInfo(null);
        setPrevPageInfo(null);
        let queryParams = search ? `&search=${search}` : '';
        queryParams = pageInfo ? queryParams + '&pageInfo=' + pageInfo : '';
        fetchProducts(`/orders?storeId=${selectedStoreId}${queryParams}&filter=${selectedFilter}&page=${page}`);
    }, [setSearch, search, selectedFilter, setSelectedFilter, page, setPage, pageInfo]);

    const removeJob = async (jobId) => {
        const response = await PLatformClient.delete(`/jobs/${jobId}`);
        setJobs((prev) =>
            prev.filter((job) => job?.id !== jobId)
        );
    }

    return (
         <div className="App-page-content">
             <h1 className="page-title">Orders</h1>
             { ordersCount && <Grid container spacing={6} columns={25}>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '3', title: "24H Net Income", "insight": "$" + ordersCount?.REVENUE_24H.toFixed(2) }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '2', title: "Order Queue", "insight": ordersCount?.IN_ORDER }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '1', title: "Pending Orders", "insight": ordersCount?.PENDING }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '3', title: "Ordered", "insight": ordersCount?.ORDERED }}/>
                 </Grid>
             </Grid>}
             <div className="page-inner-content">
                 { !(data && stores.length > 0) && <>
                     <Skeleton animation="wave" variant="rounded" height={80} style={{ borderRadius: '16px'  }} />
                     <Skeleton variant="rounded" height={80} style={{ borderTopLeftRadius: 16, borderTopRightRadius: 16, marginTop: 30  }} />
                     <Skeleton animation="wave" variant="rounded" height={120} style={{   }} />
                     <Skeleton animation="wave" variant="rounded" height={120} style={{   }} />
                     <Skeleton animation="wave" variant="rounded" height={120} style={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16  }} />
                 </> }
                 { data && stores.length > 0 && credits >= 0 && jobs.length > 0 && <JobsComponent jobs={jobs} removeJob={removeJob} /> }
                 { data && stores.length > 0 && credits >= 0 && <TableComponent itemsInProgress={itemsInProgress} checked={checked} setChecked={setChecked} setSelectedFilter={setSelectedFilter} selectedFilter={selectedFilter} setJobs={setJobs} page={page} setPage={setPage} data={data} nextPageInfo={nextPageInfo} prevPageInfo={prevPageInfo} setPageInfo={setPageInfo} setSearch={setSearch} stores={stores} selectedStoreId={selectedStoreId} setSelectedStoreId={setSelectedStoreId} credits={credits} />}
             </div>
         </div>
    );
}

export default ProductOptimizerPage;
