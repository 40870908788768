import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import "./App.scss";
import MainLayout from "./layouts/MainLayout";
import Login from "./pages/login";
import ProductOptimizerPage from "./pages/productOptimizer"
import UnifiedOrdersPage from "./pages/unifiedOrders"
import {useSelector} from 'react-redux';
import DashboardPage from "./pages/dashboard";
import StoresPage from "./pages/stores";
import BillingPage from "./pages/billing";
import Signup from "./pages/signup";
import ResetPassword from "./pages/resetPassword";
import { useMediaQuery } from 'react-responsive';
import MobilePage from "./pages/mobile";
import RegistrationComplete from "./pages/registrationComplete";

function App() {
const isMobile = useMediaQuery({ maxWidth: 767 });

const authToken = useSelector((state) => state.authToken);
  return (
            <BrowserRouter>
            <Routes>
              {authToken ? isMobile ?<Route path="/dashboard" element={ <MobilePage/>} /> : (
                <>
                  <Route path="/" element={<MainLayout />} >
                      <Route path="/dashboard" element={<DashboardPage />} />
                      <Route path="/orders" element={<ProductOptimizerPage />} />
                      <Route path="/orders-v2" element={<UnifiedOrdersPage />} />
                      <Route path="/stores" element={<StoresPage />} />
                      <Route path="/billing" element={<BillingPage />} />
                  </Route>
                  <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/registration-complete" element={<RegistrationComplete />} />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                </>
              ) : (
                <>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/registration-complete" element={<RegistrationComplete />} />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </>
              )}
            </Routes>
          </BrowserRouter>
  );
}

export default App;
