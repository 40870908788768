import React, {useState} from "react";
import Welcome from "../../components/welcome/Welcome";
import "./style.scss";
import {useDispatch} from 'react-redux';
import {NavLink} from "react-router-dom";
import axios from "axios";

export default function ResetPassword() {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showVerification, setShowVerification] = useState(false);

    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("t")

    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };

    const handleSignup = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidUsername = emailPattern.test(username);
        if (!isValidUsername) {
            setError("Email is invalid.")
        }

        axios
            .post('https://api.drop-shipping.ai/resetPassword', { username })
            //.post('http://localhost:8080/resetPassword', { username })
            .then(response => {
                setShowVerification(true);
            })
            .catch(error => {
                setError(error.response.data.message);
            });
    };


    const handleReset = () => {
        if (password.length >= 6) {
            axios
                .post('https://api.drop-shipping.ai/resetPassword?t=' + token, { password })
                //.post('http://localhost:8080/resetPassword?t=' + token, { password })
                .then(response => {
                    window.location.href = "/login"
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        } else {
            setError("Password should contains at least 6 characters")
        }
    }

    return (
    <div className="login-page">
      <Welcome />
        { token && <div className="signin-container">
            <h4>PASSWORD RESET</h4>
            <form>
                <div className="form-group">
                    <label>* New Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', verticalAlign: 'middle', textAlign: 'center' }}>



                </div>
                <button type="button" onClick={handleReset}>
                    Reset
                </button>
                { error && <span className="error-msg">* {error}</span>}
            </form>
            </div>
            || !showVerification &&  (<div className="signin-container">
          <h4>PASSWORD RESET</h4>
          <form>
              <div className="form-group">
                <label>* Email</label>
                <input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', verticalAlign: 'middle', textAlign: 'center' }}>



              </div>
              <button type="button" onClick={handleSignup}>
                Signup
              </button>
              { error && <span className="error-msg">* {error}</span>}
            </form>
              <div className="signup">
                  <span>Remembered?</span>
                  <NavLink to="/login" className="signup-link">
                      <span>Login</span>
                  </NavLink>
              </div>
        </div>) || (<div style={{ textAlign: 'center' }} className="signin-container">
            <h4 style={{ fontSize: 24 }}>One more step.</h4>
            <p>You're password reset link was sent an email to {username}</p>
            <p>Just click on the link in that email to complete the password reset.</p>
            <p>If you don't see it, you may need to <b>check you spam</b> folder</p>
        </div>)}

    </div>
  );
}
