// authToken.js

import {createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Action types
const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
const CLEAR_AUTH_TOKEN = 'CLEAR_AUTH_TOKEN'; // Add a new action type

// Action creators
export const setAuthToken = (token) => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

export const clearAuthToken = () => ({
  type: CLEAR_AUTH_TOKEN, // Action for clearing the token
});

export const getAuthToken = () => {
  return store.getState().authToken;
};

// Reducer
const initialState = {
  authToken: null,
};

const authTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    case CLEAR_AUTH_TOKEN: // Handle the new action to clear the token
      return {
        ...state,
        authToken: null,
      };
    default:
      return state;
  }
};

// Redux Persist configuration
const persistConfig = {
  key: 'authToken',
  storage,
};

// Create the persisted reducer
const persistedAuthTokenReducer = persistReducer(persistConfig, authTokenReducer);

// Create the Redux store
const store = createStore(persistedAuthTokenReducer);

// Create the persisted store
const persistor = persistStore(store);

export { store, persistor };
